exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/opt/build/repo/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-contact-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/opt/build/repo/content/pages/contact/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-contact-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-adults-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/adults/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-adults-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-children-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/children/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-children-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-seniors-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/seniors/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-seniors-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

